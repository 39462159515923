import React from 'react';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';

// Icône Waze personnalisée
function WazeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M22.67 12.186c0-2.96-2.274-5.22-5.288-5.22-3.465 0-5.572 2.308-5.572 5.25 0 1.153.478 2.198 1.193 2.915l.088.07-2.11 2.02-1.112-1.073-.943.95 2.167 2.097c-.4.234-.862.384-1.342.425l-.136.012c-1.237.104-2.296-.436-2.296-.436-.164-.094-.336-.143-.51-.143-.523 0-1.023.41-1.023.41l-.31.28c-.54.4-.976.725-1.387 1.04-.78.607-1.374 1.06-1.88 1.5-.02.02-.042.037-.062.057-.02.015-.038.036-.058.056-.12.132-.2.28-.2.47 0 .35.268.57.573.57.236 0 .376-.104.454-.177l.03-.028c.027-.02.056-.04.083-.06 1.017-.736 1.86-1.342 2.685-1.932l.368-.275.117-.09c.07-.05.142-.1.21-.152 1.09.488 2.148.678 3.098.594 1.017-.092 1.934-.482 2.633-1.057l.033-.03 1.845 1.785.94-.95-1.845-1.783 2.17-2.1.94.952-.137.126c-.095.085-.228.19-.305.28 1.607-1.196 2.68-3.157 2.68-5.286zM6.354 12.166c-.806 0-1.492-.7-1.492-1.55 0-.85.684-1.546 1.492-1.546.806 0 1.49.7 1.49 1.55 0 .85-.684 1.546-1.49 1.546zm8.106 0c-.806 0-1.49-.7-1.49-1.55 0-.85.684-1.546 1.49-1.546.806 0 1.49.7 1.49 1.55 0 .85-.684 1.546-1.49 1.546z" />
    </SvgIcon>
  );
}

function WazeButton({ latitude, longitude }) {
  const wazeUrl = `https://www.waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;

  return (
    <Button
      variant="contained"
      startIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.2em"
          height="1.2em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M20.54 6.63c.69.94 1.15 2.04 1.35 3.19c.21 1.25.11 2.52-.31 3.72a7.35 7.35 0 0 1-2 3.06a9.1 9.1 0 0 1-2.26 1.58c.41 1.07-.13 2.27-1.2 2.68c-.24.09-.49.14-.74.14a2.08 2.08 0 0 1-2.07-2h-3.07c-.11 1.14-1.13 2-2.27 1.87c-1.06-.1-1.86-.98-1.88-2.04c.01-.19.04-.39.1-.57a8.37 8.37 0 0 1-4-2.85c-.33-.44-.23-1.07.23-1.41c.18-.14.4-.22.63-.22c.72 0 1-.25 1.17-.63c.24-.72.38-1.47.39-2.23c.03-.53.09-1.05.17-1.57A7.3 7.3 0 0 1 7.5 5c1.66-1.3 3.69-2 5.79-2c1.43 0 2.84.35 4.11 1a8.7 8.7 0 0 1 3.14 2.63m-3.82 10.68c1.78-.81 3.18-2.27 3.87-4.1c1.62-4.94-2.59-9.16-7.3-9.16c-.35 0-.71.02-1.06.07C9.36 4.5 6.4 6.5 5.81 9.5c-.38 2 .19 5.29-2.76 5.29C4 16 5.32 16.93 6.81 17.37c.85-.76 2.16-.68 2.93.18c.11.12.2.25.26.39h3.55c.52-1.02 1.78-1.44 2.8-.9c.15.08.25.17.37.27m-5.75-7c-.58.03-1.09-.41-1.12-1c-.03-.58.42-1.08 1-1.12c.58-.03 1.09.42 1.12 1.06a1 1 0 0 1-.97 1.04zm4.69 0c-.58.03-1.09-.41-1.12-1c-.04-.58.42-1.08 1-1.12c.58-.03 1.09.42 1.12 1.06c.02.55-.41 1.02-1 1.04zm-5.95 1.76c-.06-.28.13-.57.41-.62s.56.13.62.41a2.5 2.5 0 0 0 2.58 1.74c1.14.06 2.18-.64 2.57-1.72c.14-.26.46-.38.71-.23c.18.1.29.27.29.47c-.19.71-.63 1.33-1.23 1.76c-.69.48-1.5.75-2.34.76h-.11c-1.63.07-3.1-1-3.53-2.58z"
          />
        </svg>
      }
      onClick={() => window.open(wazeUrl, '_blank')}
      sx={{
        backgroundColor: '#e3d5ca',
        color: 'black',
        '&:hover': {
          backgroundColor: '#d5bdaf', // couleur plus foncée pour l'état survolé
        },
      }}
    >
      Waze
    </Button>
  );
}

export default WazeButton;
